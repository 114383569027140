import React from "react";

import logo from "../assets/images/mysankofa-logo-2.png";

import missionSVG from "../assets/svg/mission.svg";
import valuesSVG from "../assets/svg/values.svg";
import visionSVG from "../assets/svg/vision.svg";

export const AboutUs = () => {
  return (
    <div id="aboutUs">
      <h2 className="title">About Us</h2>
      <div className="container">
        <section className="aboutSankofa">
          <div className="paragraph">
            <h3>Formation:</h3>
            <p>
              In the heart of 2020 pandemic just at the time it has subsided,
              many individuals were uncertain and still afraid to come out
              Prince Joshua Kofi Morrison Budukuma, stood tall with courage and
              gave hope to various community members young and old alike to come
              out, put another dark chapter in human course behind them and keep
              living. People positively received his message and came out in
              droves to witness and be part of the{" "}
              <a href="/">
                <strong>MYSANKOFA GLOBAL ROYALTY</strong>
              </a>{" "}
              initiative. His thoughtful vision after many years has come to
              fruition and the community is loving it.
            </p>
          </div>
          <div className="imageContainer">
            <img src={ logo } alt="" />
          </div>
        </section>
        <section className="missionVisionValues">
        <div className="container mission">
          <div className="imageContainer">
            <img src={missionSVG} alt="" />
          </div>
          <h3>Mission</h3>
          <p>
            To strive for higher cultural and economic coperations, fostering
            stronger global relationships which would create opportunities for
            mutual benefit.
          </p>
          {/* <a href="#"><span>Learn More</span> <i className="ri-arrow-right-line"></i></a> */}
        </div>
        <div className="container vision">
          <div className="imageContainer">
            <img src={visionSVG} alt="" />
          </div>
          <h3>Vision</h3>
          <p>
            It is our vision to establish the African Diaspora Cultural Center
            where educational informative programs will be available to all,
            especially, children, youth, and, various communities at large to
            come learn and exchange knowledge. Which would engage and encourage youth to
            reach their highest potential. Additionally, we seek to collaborate
            with various institutions as well as other cultures on
            educational programs.
          </p>
          {/* <a href="#"><span>Learn More</span> <i className="ri-arrow-right-line"></i></a> */}
        </div>
        <div className="container values">
          <div className="imageContainer">
            <img src={valuesSVG} alt="" />
          </div>
          <h3>Values</h3>
          <p>
            To use cultural and traditional practices as means to building a
            secured cohesive community where people are empowered to believe in
            themselves, respect others, and live in harmony with a sense of
            purpose and community.
          </p>
          {/* <a href="#"><span>Learn More</span> <i className="ri-arrow-right-line"></i></a> */}
        </div>
      </section>
      </div>
    </div>
  );
};
