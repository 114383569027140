import React from "react";

export const ContactUs = () => {
  return (
    <div id="contactUs">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdvEZbxYN8iTF-KBN7kl9y9KwR0x6woy6i0n88rr5yM41XC6Q/viewform?embedded=true"
        width="100%"
        height="950px"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        title="Contact Us"
      >
        Loading…
      </iframe>
    </div>
  );
};
