import React from "react";

import RoyalEssenceWhite from "../assets/images/ROYAL ESSENCE alternate.png";

import CountDown from "./CountDown";

export const RoyalEssenceCountDown = () => {
  return (
    <div id="RoyalEssenceCountDown">
      <div className="container">
        <div className="imageContainer">
          <img src={RoyalEssenceWhite} alt="Royal Essence 2025" />
        </div>
        <div className="countDown">
          <CountDown targetDate="2025-04-26T00:00:00" />
        </div>
      </div>
    </div>
  );
};