import React, { useCallback } from "react";
import { DotButton, useDotButton } from "./EmblaCarouselDotButton";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./EmblaCarouselArrowButtons";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";

import image1 from "../assets/images/King and Queen.png";
import image2 from "../assets/images/Throne.png";
import image3 from "../assets/images/umbrella copy.png";
import image4 from "../assets/images/crown-2.png"

const EmblaCarousel = (props) => {
  const { options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);

  const slider = [
    {
      image: image1,
      title: "Building & Bridging Communities Through Royalty",
      text: "Creating an environment that supports the African community to foster relations and grow with other communities",
    },
    {
      image: image2,
      title: "Creating an environment that supports the African growth",
      text: "Creating a cultural environment that fosters a sense of community and mutual respect",
    },
    {
      image: image3,
      title: "creating a collective sense of pride and belonging",
      text: "Strengthening bonds across diverse groups and encouraging inclusion of culture",
    },
    {
      image: image4,
      title: "Preserving Traditions for Future Generations",
      text: "Ensuring that the stories, art, and customs of the African community are passed down.",
    },
  ];

  const onNavButtonClick = useCallback((emblaApi) => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop;

    resetOrStop();
  }, []);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onNavButtonClick
  );

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi, onNavButtonClick);

  return (
    <section className="embla HeroSlider">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slider.map((slide, index) => (
            <div className="embla__slide" id="heroSection" key={index}>
              <div className="left">
                <h1>{slide.title}</h1>
                <p>{slide.text}</p>
                <div className="buttons">
                  <a href="aboutUs" className="learnMoreButton">
                    Learn More
                  </a>
                  <a href="contactUs" className="partnerWithUsButton">
                    Partner With Us
                  </a>
                </div>
              </div>
              <div className="imageContainer">
                <img src={slide.image} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"embla__dot".concat(
                index === selectedIndex ? " embla__dot--selected" : ""
              )}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default EmblaCarousel;
