import React, { useEffect } from "react";
import VenoBox from "venobox";

export const Gallery = () => {
  const importAll = (r) => r.keys().map(r);
  const images = importAll(
    require.context(
      "../assets/images/gallery-images",
      false,
      /\.(png|jpe?g|JPG|svg)$/
    )
  );

  useEffect(() => {
    new VenoBox({
      selector: ".venobox",
      numeration: true,
      infinigall: true,
      share: true,
      spinner: "rotating-plane",
      navTouch: true,
      autoplay: true,
      maxWidth: '500px',
    });
  }, []);

  return (
    <>
      <h2 className="title">Gallery</h2>
      <div className="galleryContainer">
        {images.map((image, index) => (
          <div className="imageContainer">
            <a href={image} className="venobox" data-gall="gallery">
              <img src={image} alt={index + 1} />
            </a>
          </div>
        ))}
      </div>
    </>
  );
};
