import React from 'react'

import NotFoundImage from '../assets/images/404 page not found.png'

export const PageNotFound = () => {
  return (
    <div className="pageNotFound">
        <img src={NotFoundImage} alt="404 Page Not Found" />
    </div>
  )
}
