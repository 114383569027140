import React, { useState, useEffect } from "react";

const CountDown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, [targetDate]);

  return (
    <div>
      {timeLeft.days !== undefined ? (
        <div className="countDown">
          <p>
            <span className="figure">{timeLeft.days}</span>
            <span>Days</span>
          </p>
          <span>:</span>
          <p>
            <span className="figure">{timeLeft.hours}</span>
            <span>Hours</span>
          </p>
          <span>:</span>
          <p>
            <span className="figure">{timeLeft.minutes} </span>
            <span>Minutes</span>
          </p>
          <span>:</span>
          <p className="bounce">
            <span className="figure">{timeLeft.seconds}</span>
            <span>Seconds</span>
          </p>
        </div>
      ) : (
        <span>Countdown finished!</span>
      )}
    </div>
  );
};

export default CountDown;
