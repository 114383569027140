import React, { useEffect } from "react";

// import ReactPlayer from "react-player";
import VenoBox from "venobox";

import HeroSlider from "../Components/HeroSlider";

import video1 from "../assets/videos/video1.mp4";
import symbols from "../assets/images/symbols.png";
import videoThumbnail from "../assets/images/video-thumbnail.png";
import logo2 from "../assets/images/mysankofa-logo-2.png";
import royalEssence from "../assets/images/royal essence.png";
// import crown from "../assets/images/crown-2.png";

// import CountDown from "../Components/CountDown";
import { RoyalEssenceCountDown } from "../Components/RoyalEssenceCountdown";

export const Home = () => {
  useEffect(() => {
    new VenoBox({
      selector: ".venobox",
      numeration: true,
      infinigall: true,
      share: true,
      spinner: "rotating-plane",
      navTouch: true,
      autoplay: true,
    });

    new VenoBox({
      selector: ".image",
      numeration: true,
      infinigall: true,
      share: true,
      spinner: "rotating-plane",
      navTouch: true,
      autoplay: true,
      maxWidth: "600px",
    });
  }, []);

  return (
    <div id="home">
      <HeroSlider />

      {/* <section id="heroSection">
        <div className="left">
          <h1>Building & Bridging Communities Through Royalty</h1>
          <p>
            Creating an environment that supports the African community to
            foster relations and grow with other communities
          </p>
        </div>
        <div className="imageContainer">
          <img src={crown} alt="" />
        </div>
      </section> */}

      <RoyalEssenceCountDown />

      <section id="cta">
        <h1>
          Experience the <span className="red">authentic global</span>{" "}
          <span className="gold">African majestic Royalty</span> &{" "}
          <span className="green">feel the power</span> in you
        </h1>
      </section>

      <section className="symbols">
        <img src={symbols} alt="" />
      </section>

      <section className="gallery">
        <div className="videos">
          <a
            href={video1}
            data-vbtype="video"
            data-autoplay="true"
            className="video venobox"
          >
            <img src={videoThumbnail} alt="" />
          </a>
        </div>
      </section>
      <section className="aboutUs">
        <div className="container">
          <div className="content">
            <h2>
              About <br />
              MYSANKOFA GLOBAL ROYALTY
            </h2>
            <p>
              Our organization is dedicated to fostering higher cultural and
              economic cooperation among communities and nations. Rooted in a
              purpose to empower and celebrate the unique talents of
              individuals, we aim to create pathways toward sustainability and a
              brighter future for all.
            </p>
            <a href="/aboutus" className="learnMoreButton">
              Learn More
            </a>
          </div>
          <div className="imageContainer">
            <img src={logo2} alt="" />
          </div>
        </div>
      </section>

      <section id="RoyalEssence">
        <div className="container">
          <div className="imageContainer">
            <img src={royalEssence} alt="" />
          </div>
          <h3>Theme:</h3>
          <p>bridging and building communities through cultural excellence</p>
          <a href="/royalessence" className="learnMoreButton">
            Learn More
          </a>
        </div>
      </section>
    </div>
  );
};
